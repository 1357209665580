import { template as template_3d483bce54f141fa8a3636f5a7404f97 } from "@ember/template-compiler";
const FKControlMenuContainer = template_3d483bce54f141fa8a3636f5a7404f97(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
